import React from "react";

import { navigate } from "gatsby";

import { PageProps } from "gatsby";

import { Layout, Container } from "components/layout";
import { useAuth } from "auth";
import { ViewHeader } from "components/view/ViewHeader";
import { SearchInput } from "components/common/SearchInput";
import { Table, TableFooter } from "components/common/Table";
import { useMessages } from "hooks/api";
import { AdminSidebar } from "components/view/AdminSidebar";

interface AdminMessagesPageProps extends PageProps {}

export default function AdminMessagesPage(props: AdminMessagesPageProps) {
  const { token } = useAuth();
  const messages = useMessages();

  React.useEffect(() => {
    if (!token) navigate("/login");
  }, [token]);

  return (
    <Layout>
      <ViewHeader />
      <Container hasSidebar>
        <AdminSidebar {...props} />
        <Container noPadding>
          <SearchInput onChange={messages.onChangeSearch} delay={250} />
          <Table
            data={messages.data}
            pageLength={10}
            columns={[
              {
                title: "Name",
                accesor: "name",
                render: (value, row) => (
                  <a href={`/administration/message/${row.id}`}>{value}</a>
                ),
              },
              { title: "Email", accesor: "email" },
              { title: "Message", accesor: (row) => row.message.slice(0, 100) },
            ]}
          >
            <TableFooter
              pageLength={10}
              page={Number(messages.pagination?.page) || 0}
              totalRecords={Number(messages.pagination?.totalRecords) || 0}
              onPageChange={messages.onChangePage}
            />
          </Table>
        </Container>
      </Container>
    </Layout>
  );
}
